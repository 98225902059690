:root {
    --sl-color-primary-50: #ffe9e0; /* Lightest shade */
    --sl-color-primary-100: #ffccb3;
    --sl-color-primary-200: #ffab80;
    --sl-color-primary-300: #ff8a4d;
    --sl-color-primary-400: #ff691a;
    --sl-color-primary-500: #E24A00; /* Primary color */
    --sl-color-primary-600: #cc4300;
    --sl-color-primary-700: #b33b00;
    --sl-color-primary-800: #993400;
    --sl-color-primary-900: #802c00; /* Darkest shade */

    /* Danger Color Palette */
    --sl-color-danger-50: #ffe5e5; /* Lightest shade */
    --sl-color-danger-100: #ffb3b3;
    --sl-color-danger-200: #ff8080;
    --sl-color-danger-300: #ff4d4d;
    --sl-color-danger-400: #ff1a1a;
    --sl-color-danger-500: #e60000; /* Danger color */
    --sl-color-danger-600: #cc0000;
    --sl-color-danger-700: #b30000;
    --sl-color-danger-800: #990000;
    --sl-color-danger-900: #800000; /* Darkest shade */

    --sl-color-primary-text: white;
}

app-root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

app-maincontent {
    flex: 1 1 auto;
}

.mxy {
    margin-left: 1rem;
    margin-right: 1rem;
}

.p {
    padding: 1rem;
}

.pointer {
    cursor: pointer;
}

.highlitght:hover {
    background-color: whitesmoke;
}

.dropdown-content {
    min-height: 200px !important;
    overflow: hidden;
}

ul.dropdown-content.select-dropdown li:disabled span {
    color: black;
}

ul.dropdown-content.select-dropdown li:not(.disabled) span {
    color: #b83700;
}

input[type='checkbox']:checked + label:before {
    border-bottom-color: #b83700;
    border-right-color: #b83700;
    border-top-color: transparent;
    border-left-color: transparent;
}

input[type='checkbox']:checked + label:after {
    border-bottom-color: #b83700;
    border-right-color: #b83700;
    border-top-color: transparent;
    border-left-color: transparent;
}

.tabs a {
    color: #E24A00 !important;
}

.tabs .indicator {
    background-color: #E24A00 !important;
}

.btn-icon {
    width: 40px !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: #E24A00 !important;
    text-align: center !important;
    box-shadow: none !important;
}

.customcolor {
    background-color: #E24A00 !important;
}

.customcolor-text {
    color: #E24A00 !important;
}

.comments-display {
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px dotted gray;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
    overflow-y: scroll;
    padding: 10px 0;
    resize: none;
    color: gray;
}

.error {
    color: #E24A00;
    font-size: 11px;
    width: 100%;
    height: 12px;
}

.error-textarea {
    color: #E24A00;
    font-size: 11px;
}

.relative {
    position: relative;
}

sl-dialog {
    --width: 60%;

    &::part(panel) {
        height: 70vh;
    }

    &::part(header) {
        --sl-font-size-large: 2.5rem;
        align-items: start;

    }

    &::part(close-button) {
        padding-top: var(--header-spacing);

        sl-icon-button::part(base) {
            padding: 0;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: end;
        gap: 15px;
    }
}

sl-drawer {
    &::part(header-actions) {
        display: flex;
        align-items: center;
    }
}

.container .row {
    margin-left: unset;
    margin-right: unset;
}
